import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import './App.css';
import About from './pages/About';
import { I18nextProvider } from 'react-i18next';
import NavigationBar from './pages/components/NavigationBar';
import Footer from './pages/components/Footer';
import ContactPage from './pages/Contact';
import NotFound from './pages/NotFound';
import i18n from './i18n'; 
import LuxuryValetParking from './pages/luxuryValetParking';
import GolfCartService from './pages/golfCartService';
import ChauffeurService from './pages/chauffeurService';
function App() {

  return (
    <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <NavigationBar/>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<ContactPage />} />
        <Route path='/valet-parking' element={<LuxuryValetParking />} />
        <Route path='/golf-cart' element={<GolfCartService />} />
        <Route path='/chauffeur-service' element={<ChauffeurService />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      </I18nextProvider>
    </BrowserRouter>
  );
}

export default App;

import React, { useEffect, useMemo, useState } from 'react';
import Hero from './components/Hero';
import './Home.css';
import SingleSection from './components/ui/SingleSection';
import ContactSection from './components/ContactSection';
import { useTranslation } from 'react-i18next';
import ContactDetails from './components/ContactDetails';

export default function LuxuryValetParking() {
  const { t, i18n } = useTranslation();
  
  const [descPosition, setDescPosition] = useState("");
  const [currentSections, setCurrentSections] = useState([]);
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
    }, []);
  useEffect(() => {
    document.documentElement.lang = i18n.language;
    document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
    setDescPosition(i18n.language === 'ar' ? 'text-end' : 'text-start');
  }, [i18n.language]);

  useEffect(() => {
    const customCursor = document.getElementById('custom-cursor');
    const hoverContainer = document.querySelector('.hover-container');
  
    const updateCursorPosition = (event) => {
      if (customCursor) {
        customCursor.style.top = `${event.clientY}px`;
        customCursor.style.left = `${event.clientX}px`;
      }
    };
  
    const handleMouseMove = (event) => {
      if (window.innerWidth >= 768) {
        updateCursorPosition(event);
        if (hoverContainer && hoverContainer.matches(':hover')) {
          customCursor.classList.add('zoom');
          customCursor.style.zIndex = 9999999;
        } else {
          customCursor.classList.remove('zoom');
        }
      } else {
        if (customCursor) {
          customCursor.style.top = '';
          customCursor.style.left = '';
          customCursor.classList.remove('zoom');
        }
      }
    };
  
    window.addEventListener('mousemove', handleMouseMove);
  
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);
  
  const sectionData = useMemo(() => [
    {
      id: 'luxuryValetParking',
      background: 'url(/assets/img/services/valet.png), rgba(0, 0, 0, 0.42)',
      data: {
        heading: t('luxuryValetParking.engagingIntroduction.heading'),
        description: t('luxuryValetParking.engagingIntroduction.description'),
        color: 'white',
        textPosition: 'bottom',
        descPosition: i18n.language === 'ar' ? 'text-end' : 'text-start'
      },
      align: 'end',
    },
    {
      id: 'whyChoose',
      background: 'url(/assets/img/services/vision.png), rgba(0, 0, 0, 0.42)',
      data: {
        heading: t('luxuryValetParking.whyChoose.heading'),
        description: `
          <ul>
            <li>${t('luxuryValetParking.whyChoose.highProfessionalism')}</li>
            <li>${t('luxuryValetParking.whyChoose.completeSecurity')}</li>
            <li>${t('luxuryValetParking.whyChoose.customerConvenience')}</li>
            <li>${t('luxuryValetParking.whyChoose.flexibility')}</li>
          </ul>
        `,
        color: 'white',
        textPosition: 'top',
        descPosition: i18n.language === 'ar' ? 'text-end' : 'text-start'
      },
      align: 'start',
    },
    {
      id: 'servicesWeProvide',
      background: 'url(/assets/img/services/experience.png), rgba(0, 0, 0, 0.42)',
      data: {
        heading: t('luxuryValetParking.servicesWeProvide.heading'),
        description: `
          <ul>
            <li>${t('luxuryValetParking.servicesWeProvide.parkingHotels')}</li>
            <li>${t('luxuryValetParking.servicesWeProvide.shoppingMalls')}</li>
            <li>${t('luxuryValetParking.servicesWeProvide.specialEvent')}</li>
          </ul>
        `,
        color: 'white',
        textPosition: 'top',
        descPosition: i18n.language === 'ar' ? 'text-end' : 'text-start'
      },
      align: 'start',
    },
    {
      id: 'testimonials',
      background: 'url(/assets/img/services/Hospitality.jpg), rgba(0, 0, 0, 0.42)',
      data: {
        heading: t('luxuryValetParking.testimonials.heading'),
        description: `
          <ul>
            <li>${t('luxuryValetParking.testimonials.testimon1')}</li>
            <li>${t('luxuryValetParking.testimonials.testimon2')}</li>
            <li>${t('luxuryValetParking.testimonials.testimon3')}</li>
          </ul>
        `,
        color: 'white',
        textPosition: 'top',
        descPosition: i18n.language === 'ar' ? 'text-end' : 'text-start'
      },
      align: 'end',
    },
    {
      id: 'contactUs',
      background: 'url(/assets/img/services/Golf.png), rgba(0, 0, 0, 0.42)',
      data: {
        heading: t('luxuryValetParking.contactUs.heading'),
        description: t('luxuryValetParking.contactUs.description'),
        color: 'white',
        textPosition: 'top',
        descPosition: i18n.language === 'ar' ? 'text-end' : 'text-start'
      },
      align: 'end',
    },
  ], [i18n.language, t]);

  useEffect(() => {
    const updateSections = () => {
      if (window.innerWidth <= 768) {
        setCurrentSections(sectionData.map(section => ({
          ...section,
          data: {
            ...section.data,
            description: section.data.description.replace(/<ul>|<\/ul>|<li>|<\/li>/g, '') // Remove bullet points for mobile
          }
        })));
      } else {
        setCurrentSections(sectionData);
      }
    };

    updateSections(); // Initial check
    window.addEventListener('resize', updateSections);

    return () => {
      window.removeEventListener('resize', updateSections);
    };
  }, [sectionData]);

  return (
    <div>
      <div id='custom-cursor' style={{zIndex: 99999999}}/>
      <Hero data={descPosition} />
      {currentSections.map((section) => (
        <div
          key={section.id}
          className={`bg-custom h-400 d-flex align-items-${section.align}`}
          id={section.id}
          style={{ background: section.background }}
        >
          <SingleSection data={section.data} />
        </div>
      ))}
      <ContactSection />
      <ContactDetails data={descPosition} />
    </div>
  );
}
import React from 'react';
import { Container } from 'react-bootstrap';
import { motion } from 'framer-motion';
import AnimatedText from '../AnimatedText';

const SingleSection = ({ data, onHeadingClick }) => {
    const { heading, color, description, textPosition, descPosition } = data;

    const descriptionVariants = {
        hidden: { opacity: 0, y: 40 },
        visible: { opacity: 1, y: 0 } 
    };

    const handleHeadingClick = () => {
        if (onHeadingClick) {
            onHeadingClick(); // Trigger the provided click handler
        }
    };

    return (
        <Container
            md
            className={`my-3 py-3 h-auto d-flex ${
                textPosition === 'center' ? ' align-items-center' : ''
            } ${textPosition === 'top' ? ' align-items-start' : ''} ${
                textPosition === 'bottom' ? ' align-items-end' : ''
            }`}
        >
            <div
                className={`${textPosition === 'center' ? 'text-center' : ''} ${
                    textPosition === 'top' ? ' text-start' : ''
                } ${textPosition === 'bottom' ? 'text-start' : ''}`}
            >
                <div className="bg-black-custom w-auto rounded-5 h-auto p-3">
                {onHeadingClick ? (
                        <div onClick={handleHeadingClick} style={{ cursor: 'pointer' }}>
                            <AnimatedText text={heading} color={color} position={textPosition} />
                        </div>
                    ) : (
                        <AnimatedText text={heading} color={color} position={textPosition} />
                    )}
                    
                    
                    {/* Animate description text */}
                    <motion.div
                        className={`mt-1 fs-4 mbl-des ${descPosition}`}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }} // Animate only once when in view
                        transition={{ duration: 0.5, ease: 'easeOut' }}
                        variants={descriptionVariants}
                        dangerouslySetInnerHTML={{ __html: description }}
                    >
                        {/* {description} */}
                    </motion.div>
                </div>
            </div>
        </Container>
    );
};

export default SingleSection;
